import React, { useState, useEffect, Component } from 'react';
import { db } from './firebase';
import { Routes, Route, Link } from "react-router-dom";

import Search from './Search.js'

import logo from './images/ReciLogoNew.png';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { query, collection, getDocs, where, onSnapshot } from "firebase/firestore";
import ReciSelected from './ReciSelected';
import { reciCategories } from './utils/dataUtils';

function Header() {

  const [reciCount, setReciCount] = useState({});

  useEffect(() => {
    const q = query(collection(db, process.env.REACT_APP_COLLECTION_POSTS));
    let countObj = {};

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      querySnapshot.docs.forEach((doc) => {
        const cuisine = doc.data().Cuisine;
        countObj[cuisine] = (countObj[cuisine] || 0) + 1;
      });
      setReciCount(countObj);
      console.log(countObj, "count");
    });

    return () => unsubscribe();
  }, [setReciCount]);





  return (
    <div>
      <div className='top-header-bar text-center'>
        <img src={logo} />
      </div>
      <Search />
      <div >
        <div className='category_list'>
          <ul className='category_list_item'>
            {reciCategories.map((category) =>
              <li >
                <Link to={`/category/${category.catlink}`} ><img src={category.catimg} alt={category.catlabel} />
                  <h4 className='text-center'>{category.catlabel}</h4>
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>

    </div>

  );
}


export default Header;
