import React, { useState, useEffect } from 'react';
// import logo from './ReciLogo.png';
import {db} from './firebase';
// import YouTube from 'react-youtube';
// import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
// import Header1 from './header1.js';
// import {
//   AppBar,
//   Toolbar,
//   Typography,
//   makeStyles,
//   Button,
// } from "@material-ui/core";
// import Serving_Size_Icon from './images/serving_size.png';
// import Min_Cook_Icon from './images/min_cook.png';
// import ReciLogo from './images/ReciLogo.png';
// import Gem_Badge from './images/gem_badge.png';
// import Shelf_Icon from './images/Shelf.png';
// import User_Icon from './images/user.png';
// import Website_Icon from './images/website.png';
// import Cook_Icon from './images/cook.png';
// import Calendar_Icon from './images/calendar.png';
// import Share_Icon from './images/share.png';
import {Link,useParams } from "react-router-dom";
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
// import {changeprofile} from './_actions/profileActions';
// import {useSelector } from 'react-redux';

// import {changepost} from './_actions/postActions';
// import {selectedcookbook} from './_actions/cookbookActions';
// import {selectedcookbook1} from './_actions/cookbookActions';
import TopReciSelected from './CookBookSelectedTopReci';
import { query, collection, where,onSnapshot } from "firebase/firestore";
function MenuSelected(props) {
  // const [info , setInfo] = useState([]);
  const [Recis, setRecis] = useState([]);
  // const [Recis1, setRecis1] = useState([]);
  // const [Posts, setPosts] = useState([]);
  const [AllRecis, setAllRecis] = useState([]);
  const [Users, setUsers] = useState([]);
  // const dispatch = useDispatch();

  // const state = useSelector((state)=> state);
   const params = useParams();


  useEffect(() => {
    const q = query(collection(db, process.env.REACT_APP_COLLECTION_COOKBOOKS), where("myID", "==", params.id))
    onSnapshot(q, (querySnapshot) => {
      setRecis(querySnapshot.docs.map(doc => ({
        id: doc.id,
        data: doc.data(),
      })))
    })
   }, []);



  // useEffect(() => {
  //   db.collection(process.env.REACT_APP_COLLECTION_COOKBOOKS).where("myID", "==", params.id).onSnapshot((snapshot) => {
  //     setRecis(
  //       snapshot.docs.map((doc) => ({
  //         id: doc.id,
  //         data: doc.data(),
  //       }))
  //     );
  //   });
  //   console.log({ Recis });
  // }, []);

//   useEffect(() => {
//     db.collection(`posts`).onSnapshot(snapshot => {
//         const posts = snapshot.docs.filter(doc => doc.data().post_id === Recis).map(doc => {
//           return { id: doc.id, ...doc.data() }
//         })
//         setRecis1(posts)
//       })
// }, [Recis1])



useEffect(() => {
  const q = query(collection(db, process.env.REACT_APP_COLLECTION_USERS))
  onSnapshot(q, (querySnapshot) => {
    setUsers(querySnapshot.docs.map(doc => ({
      id2: doc.id,
      data2: doc.data(),
    })))
  })
 }, []);
//  useEffect(() => {
//   db.collection(process.env.REACT_APP_COLLECTION_USERS).onSnapshot((snapshot) => {
//     setUsers(
//       snapshot.docs.map((doc) => ({
//         id2: doc.id,
//         data2: doc.data(),
//       }))
//     );
//   });
//   console.log({ Users });
// }, []);

  // useEffect(() => {
  //     db
  //       .collection(`posts`)
  //       .onSnapshot(snapshot => {
  //         const posts = snapshot.docs
  //         .filter(doc => doc.data().post_id === 1)
  //         .map(doc => {
  //           return { id: doc.id, ...doc.data() }
  //         })
  //         setPosts(posts)
  //       })
  // });
  useEffect(() => {
    const q = query(collection(db, process.env.REACT_APP_COLLECTION_POSTS))
    onSnapshot(q, (querySnapshot) => {
      setAllRecis(querySnapshot.docs.map(doc => ({
        postid: doc.id,
        postdata: doc.data(),
      })));
    });
   }, []);

  // useEffect(() => {
  //   db.collection(process.env.REACT_APP_COLLECTION_POSTS).onSnapshot((snapshot) => {
  //     setAllRecis(
  //       snapshot.docs.map((doc) => ({
  //         postid: doc.id,
  //         postdata: doc.data(),
  //       }))
  //     );
  //   });
  //   console.log({ Recis });
  // }, []);

  const divStyle = {
    paddingTop: '100px',
    marginRight: '5%',
    marginLeft: '5%'
  };

  // const opts = {
  //     height: '600',
  //     width: '90%',
  //     playerVars: {
  //       // https://developers.google.com/youtube/player_parameters
  //     },
  //   };


        // const ColoredLine = ({ color }) => (
        //   <hr
        //     style={{
        //       color,
        //       backgroundColor: color,
        //       height: .5
        //     }}
        //   />
        // );



  return (
<div style={divStyle} className='cookbookselect_top'>

  {Recis?.map(({id, data}) => (
      <Paper className='cookbook_select'>
        <p><b>{data.book_title}</b> by {data.user_id}</p>
        <div style={{marginRight: '5%',  marginLeft: '5%'}}>
          <h3>Recis ({data.Recis.length}):</h3>
          {data.posts.map((name,index) =>
          <p>{AllRecis?.map(({postid, postdata}) => (
            postdata.post_id === name
            ? (<Link to={`/SelectedReci/${postdata.myID}`}>{index+ 1}.{postdata.Title}</Link>)
            : null
            ))}</p>
          )}
        </div>
      </Paper>
  ))}


    </div>


  );
}

export default MenuSelected;
