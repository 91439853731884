import React, { useState, useEffect } from 'react';
// import logo from './ReciLogo.png';
import {db} from './firebase';
// import YouTube from 'react-youtube';
// import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import HTMLFlipBook from "react-pageflip";
import Serving_Size_Icon from './images/serving_size.png'
import Min_Cook_Icon from './images/min_cook.png'
import ReactPlayer from 'react-player'
import { jsPDF } from "jspdf";
import {Link,useParams, useNavigate } from "react-router-dom";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Download_PDF from './images/PDF Download.png'
import {
  AppBar,
  Toolbar,
  Typography,
  makeStyles,
  Button,
} from "@material-ui/core";

// import {changeprofile} from './_actions/profileActions';
// import {useSelector } from 'react-redux';

// import {changepost} from './_actions/postActions';
// import {selectedcookbook} from './_actions/cookbookActions';
// import {selectedcookbook1} from './_actions/cookbookActions';
import TopReciSelected from './CookBookSelectedTopReci';
import { query, collection, where,onSnapshot } from "firebase/firestore";

function CookbookSelected(props) {
  // const [info , setInfo] = useState([]);
  const [Recis, setRecis] = useState([]);
  // const [Recis1, setRecis1] = useState([]);
  // const [Posts, setPosts] = useState([]);
  const [AllRecis, setAllRecis] = useState([]);
  const [Users, setUsers] = useState([]);
  // const dispatch = useDispatch();

  // const state = useSelector((state)=> state);
   const params = useParams();
   const navigate = useNavigate();

  const flipBookRef = React.useRef();

  const handleNextPage = () => {
    if (flipBookRef.current) {
      flipBookRef.current.pageFlip().flipNext();
    }
  };

  const handlePrevPage = () => {
    if (flipBookRef.current) {
      flipBookRef.current.pageFlip().flipPrev();
    }
  };

  useEffect(() => {
    const q = query(collection(db, process.env.REACT_APP_COLLECTION_COOKBOOKS), where("myID", "==", params.id))
    onSnapshot(q, (querySnapshot) => {
      setRecis(querySnapshot.docs.map(doc => ({
        id: doc.id,
        data: doc.data(),
      })))
    })
   }, []);



  // useEffect(() => {
  //   db.collection(process.env.REACT_APP_COLLECTION_COOKBOOKS).where("myID", "==", params.id).onSnapshot((snapshot) => {
  //     setRecis(
  //       snapshot.docs.map((doc) => ({
  //         id: doc.id,
  //         data: doc.data(),
  //       }))
  //     );
  //   });
  //   console.log({ Recis });
  // }, []);

//   useEffect(() => {
//     db.collection(`posts`).onSnapshot(snapshot => {
//         const posts = snapshot.docs.filter(doc => doc.data().post_id === Recis).map(doc => {
//           return { id: doc.id, ...doc.data() }
//         })
//         setRecis1(posts)
//       })
// }, [Recis1])



useEffect(() => {
  const q = query(collection(db, process.env.REACT_APP_COLLECTION_USERS))
  onSnapshot(q, (querySnapshot) => {
    setUsers(querySnapshot.docs.map(doc => ({
      id2: doc.id,
      data2: doc.data(),
    })))
  })
 }, []);
//  useEffect(() => {
//   db.collection(process.env.REACT_APP_COLLECTION_USERS).onSnapshot((snapshot) => {
//     setUsers(
//       snapshot.docs.map((doc) => ({
//         id2: doc.id,
//         data2: doc.data(),
//       }))
//     );
//   });
//   console.log({ Users });
// }, []);

  // useEffect(() => {
  //     db
  //       .collection(`posts`)
  //       .onSnapshot(snapshot => {
  //         const posts = snapshot.docs
  //         .filter(doc => doc.data().post_id === 1)
  //         .map(doc => {
  //           return { id: doc.id, ...doc.data() }
  //         })
  //         setPosts(posts)
  //       })
  // });
  useEffect(() => {
    const q = query(collection(db, process.env.REACT_APP_COLLECTION_POSTS))
    onSnapshot(q, (querySnapshot) => {
      setAllRecis(querySnapshot.docs.map(doc => ({
        postid: doc.id,
        postdata: doc.data(),
      })));
    });
   }, []);

  // useEffect(() => {
  //   db.collection(process.env.REACT_APP_COLLECTION_POSTS).onSnapshot((snapshot) => {
  //     setAllRecis(
  //       snapshot.docs.map((doc) => ({
  //         postid: doc.id,
  //         postdata: doc.data(),
  //       }))
  //     );
  //   });
  //   console.log({ Recis });
  // }, []);

  const divStyle = {
    paddingTop: '100px',
    marginRight: '5%',
    marginLeft: '5%',
  };

  const playerStyle = {
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    maxHeight:'250px'
};


  // const opts = {
  //     height: '600',
  //     width: '90%',
  //     playerVars: {
  //       // https://developers.google.com/youtube/player_parameters
  //     },
  //   };


        // const ColoredLine = ({ color }) => (
        //   <hr
        //     style={{
        //       color,
        //       backgroundColor: color,
        //       height: .5
        //     }}
        //   />
        // );



  const handlePDFDownload = () => {
    const doc = new jsPDF();
    Recis.forEach(({ data }) => {
      doc.text(`Title: ${data.book_title}`, 10, 10);
      data.posts.forEach((name, index) => {
        const post = AllRecis.find(({ postdata }) => postdata.post_id === name);
        if (post) {
          doc.text(`${index + 1}. ${post.postdata.Title}`, 10, 20 + (index * 10));
        }
      });
      doc.addPage(); // Add a new page for each cookbook
    });
    doc.save("merged_recipes.pdf");
  };

  const handleCheckout = (price) => {
    navigate({
      pathname: '/checkout',
      state: { price: price }
    });
  };

  return (

<div style={divStyle} className='cookbookselect_top'>



  {Recis?.map(({id, data}) => (
      <Paper className='cookbook_select'>
        <p><b>{data.book_title}</b></p>  {/* by {data.user_name}*/}
        <div style={{marginRight: '5%',  marginLeft: '5%', overflowY: 'auto'}}>
          <h3>Pages ({data.posts.length}):</h3>
          {data.posts.map((name,index) =>
          <p>{AllRecis?.filter(({postdata}) => postdata.post_id === name).map(({postid, postdata}) => (
            <Link to={`/SelectedReci/${postdata.myID}`}>{index + 1}.{postdata.Title}</Link>
          ))}</p>
          )}
        {/*  <Button 
    onClick={() => handleCheckout(Recis?.map(({id, data}) => data.posts.length).reduce((a, b) => a + b, 0))}
    style={{ 
        backgroundColor: 'red', 
        color: 'white', 
        padding: '10px 20px', 
        borderRadius: '5px', 
        border: 'none', 
        fontSize: '16px', 
        cursor: 'pointer',
        transition: 'background-color 0.3s ease'
    }}
    onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#c00'} // Darker red on hover
    onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'red'} // Original red on leave
>
    Checkout
</Button> */}
        </div>
      </Paper>
  ))}
  
  

<div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
  <button onClick={handlePrevPage} style={{ cursor: 'pointer', background: 'none', border: 'none', fontSize: '24px' }}>←</button>
  <button onClick={handleNextPage} style={{ cursor: 'pointer', background: 'none', border: 'none', fontSize: '24px' }}>→</button>
</div>

<HTMLFlipBook 
  ref={flipBookRef}
  width={550}
  height={733}
  size="stretch"
  minWidth={315}
  maxWidth={1000}
  minHeight={500}
  maxHeight={1533}
  maxShadowOpacity={0.5}
  mobileScrollSupport={true}
>
  {Recis?.map(({id, data}) => (
      <Paper key={id} className='cookbook_select'>
          <h6>Index</h6>
          <div style={{ maxHeight: '375px', overflowY: 'auto' }}>
            {data.posts.map((name) => (
              <div key={name}>
                {AllRecis?.filter(({postdata}) => postdata.post_id === name).map(({postid, postdata}) => (
                  <Link key={postid} to={`/SelectedReci/${postdata.myID}`}>{postdata.Title}</Link>
                ))}
              </div>
            ))}
          </div>
      </Paper>
  ))}
  {AllRecis?.filter(({postdata}) => Recis.some(({data}) => data.posts.includes(postdata.post_id))).map(({postid, postdata}) => (
    <Paper key={postid} className='cookbook_select'>
      <Link to={`/SelectedReci/${postdata.myID}`}>{postdata.Title}</Link>
      <p><b>{postdata.Cuisine}</b>: {postdata.Title}</p>
      <p>
      <b> Author: </b>
           {postdata.author.map((name, index) => <a key={name} href={postdata.author[index].profile_URL}>{postdata.author[index].Name}</a>)}
      </p>
      <p><img src={Serving_Size_Icon} alt="Serving Size" width="20" height="20" /> Serving Size: {postdata.serving_size} <img src={Min_Cook_Icon} alt="Min Cook" width="20" height="20" /> Min Cook: {postdata.min_cook} min</p>
      <ReactPlayer url={postdata.YouTube_URL} style={playerStyle} width="100%" controls="true"/>
    </Paper>
  ))}
</HTMLFlipBook>
    
    </div>


  );
}

export default CookbookSelected;
