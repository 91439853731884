import React, { useState, useEffect, Component } from 'react';
import logo from './ReciLogo.png';
import cookbookoutline from './images/cookbookoutline.png'
import YouTube from 'react-youtube';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Header1 from './header1.js'
import {
  AppBar,
  Toolbar,
  Typography,
  makeStyles,
  Button,
} from "@material-ui/core";
import Serving_Size_Icon from './images/serving_size.png'
import Min_Cook_Icon from './images/min_cook.png'
import ReciLogo from './images/ReciLogo.png'
import Gem_Badge from './images/gem_badge.png'
import Shelf_Icon from './images/Shelf.png'
import User_Icon from './images/user.png'
import Website_Icon from './images/website.png'
import Cook_Icon from './images/cook.png'
import Calendar_Icon from './images/calendar.png'
import Share_Icon from './images/share.png'
import { Routes, Route, Link, } from "react-router-dom";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { changeprofile } from './_actions/profileActions'
import { connect, useDispatch, useSelector } from 'react-redux';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Hits } from 'react-instantsearch-dom';
import { changepost } from './_actions/postActions'
import { selectedcookbook } from './_actions/cookbookActions'
import { selectedcookbook1 } from './_actions/cookbookActions'
import { query, collection, getDocs, where, onSnapshot } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { reinsertRecipe, auth, db } from './firebase';
import Pagination from '@mui/material/Pagination';
import red_badge from './images/red_badge.png';

function Cookbooks() {
  const [info, setInfo] = useState([]);
  const [Recis, setRecis] = useState([]);
  const [Recis1, setRecis1] = useState([]);
  const [Posts, setPosts] = useState([]);
  const [AllRecis, setAllRecis] = useState([]);
  const [Users, setUsers] = useState([]);
  const dispatch = useDispatch();
  const [user, loading, error] = useAuthState(auth);

  // Pagination state
  const [page, setPage] = useState(1);
  const recipesPerPage = 36; // Number of cookbooks per page

  useEffect(() => {
    const q = query(collection(db, process.env.REACT_APP_COLLECTION_COOKBOOKS))
    onSnapshot(q, (querySnapshot) => {
      setRecis(querySnapshot.docs.map(doc => ({
        id: doc.id,
        data: doc.data(),
      })))
    })
  }, []);

  // Calculate pagination
  const indexOfLastReci = page * recipesPerPage;
  const indexOfFirstReci = indexOfLastReci - recipesPerPage;
  const currentRecis = Recis.slice(indexOfFirstReci, indexOfLastReci);
  const pageCount = Math.ceil(Recis.length / recipesPerPage);

  useEffect(() => {
    const q = query(collection(db, process.env.REACT_APP_COLLECTION_USERS))
    onSnapshot(q, (querySnapshot) => {
      setUsers(querySnapshot.docs.map(doc => ({
        id2: doc.id,
        data2: doc.data(),
      })))
    })
  }, []);


  useEffect(() => {
    const q = query(collection(db, process.env.REACT_APP_COLLECTION_POSTS))
    onSnapshot(q, (querySnapshot) => {
      setAllRecis(querySnapshot.docs.map(doc => ({
        postid: doc.id,
        postdata: doc.data(),
      })))
    })
  }, []);

  const divStyle = {
    paddingTop: '100px'
  };

  const opts = {
    height: '600',
    width: '90%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
    },
  };

  const searchClient = algoliasearch('SXH1UNR77P', '5cbea671c4708dfc63c7ff6845a218a2');

  const ColoredLine = ({ color }) => (
    <hr
      style={{
        color,
        backgroundColor: color,
        height: .5
      }}
    />
  );

  const handlePageChange = (event, value) => {
    setPage(value);
    // Scroll to the top of the cookbooks section if needed
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const customPaginationStyle = {
    '& .MuiPaginationItem-root': {
      backgroundColor: 'transparent',
      color: '#000',
      '&.Mui-selected': {
        backgroundColor: 'transparent',
        '&::after': {
          content: '""',
          position: 'absolute',
          width: '100%',
          height: '100%',
          backgroundImage: `url(${red_badge})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          zIndex: -1
        }
      }
    }
  };

  return (
    <div className='web-container' style={{
      padding: '20px',
      width: '100%',
      boxSizing: 'border-box'
    }}>
      <div className='d-flex justify-content-between mb-2'>
        <h1 className='title-2'> Cookbooks </h1>
      </div>

      {currentRecis.length ? (
        <div style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
          gap: '20px',
          width: '100%'
        }}>
          {currentRecis.map(({ id, data }) => (
            <div key={id} style={{
              aspectRatio: '1',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '15px',
              boxSizing: 'border-box',
              backgroundColor: '#fff',
              borderRadius: '10px',
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
            }}>
              {/* Image Container */}
              <div style={{ 
                width: '100%',
                height: '60%',
                position: 'relative',
                overflow: 'hidden'
              }}>
                <img 
                  src={data.icon}
                  alt="Cookbook cover"
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    borderRadius: '8px'
                  }}
                />
              </div>

              {/* Content Container */}
              <div style={{ 
                width: '100%',
                height: '40%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                alignItems: 'center',
                padding: '10px 0'
              }}>
                <Link 
                  to={`/CookbookSelected/${data.myID}`} 
                  style={{ 
                    textDecoration: 'none',
                    width: '100%',
                    textAlign: 'center'
                  }}
                >
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                    alignItems: 'center'
                  }}>
                    <p style={{ 
                      margin: 0,
                      fontSize: '1.1em',
                      fontWeight: 'bold',
                      color: '#333'
                    }}>
                      {data.book_title} {/* by {data.user_name} */ }
                    </p>
                    
                    <p style={{ 
                      margin: 0,
                      fontSize: '0.9em',
                      color: '#666'
                    }}>
                      <b><span style={{color:'red'}}>Pages: </span></b>
                      {data.posts.length}
                    </p>

                    <Button 
                      onClick={() => { 
                        dispatch(selectedcookbook(data.book_id)); 
                        dispatch(changepost(data.posts[0])); 
                      }}
                      style={{
                        backgroundColor: 'red',
                        color: 'white',
                        borderRadius: '25px',
                        padding: '8px 16px',
                        border: 'none',
                        fontSize: '0.9em',
                        cursor: 'pointer',
                        transition: 'background-color 0.3s',
                        width: 'auto',
                        whiteSpace: 'nowrap'
                      }}
                      onMouseOver={(e) => e.target.style.backgroundColor = '#d32f2f'}
                      onMouseOut={(e) => e.target.style.backgroundColor = 'red'}
                    >
                      {data.book_title}
                    </Button>
                  </div>
                </Link>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center">No records found</div>
      )}

      {/* Pagination Controls */}
      <div style={{ 
        display: 'flex', 
        justifyContent: 'center',
        margin: '20px 0'
      }}>
        <Pagination 
          count={pageCount}
          page={page}
          onChange={handlePageChange}
          size="large"
          showFirstButton
          showLastButton
          sx={customPaginationStyle}
        />
      </div>
    </div>
  );
}

export default Cookbooks;
