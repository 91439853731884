import React, { useState, useEffect, Component } from 'react';
import { db } from './firebase';
import { Routes, Route, Link } from "react-router-dom";

import { InstantSearch, SearchBox, Hits } from 'react-instantsearch-dom';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { query, collection, getDocs, where, onSnapshot } from "firebase/firestore";
import { reciCategories } from './utils/dataUtils';
import Search from './Search.js'


function Header2() {



  const [reciCount, setReciCount] = useState({});

  useEffect(() => {
    const q = query(collection(db, process.env.REACT_APP_COLLECTION_POSTS));
    let countObj = {};

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      querySnapshot.docs.forEach((doc) => {
        const cuisine = doc.data().Cuisine;
        countObj[cuisine] = (countObj[cuisine] || 0) + 1;
      });
      setReciCount(countObj);
      console.log(countObj, "count");
    });

    return () => unsubscribe();
  }, [setReciCount]);



  return (
    <>
      <Search />

      <Row className='mobile_view ' style={{ overflowX: 'scroll', whiteSpace: 'nowrap', height: '230px' }}>
        <Col >
          <div className='category_list'>
            <ul className='category_list_item'>
              {reciCategories.map((category) =>
                <li ><Link to={`/category/${category.catlink}`} ><img src={category.catimg} alt={category.catimg} /><h3>{category.catlabel}</h3></Link></li>
              )}
            </ul>
          </div>
        </Col>

      </Row>
    </>
  );
}


export default Header2;
