import {
  AppBar,
  Toolbar,
  Typography,
  makeStyles,
  Button,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import Cookbooks from './Cookbooks.js'
import Menus from './Menus.js'
import Profiles from './Profiles.js'
import Home from './Home.js'
import ProfileSelected from './ProfileSelected.js'
import {
  Router,
  Routes, // instead of "Switch"
  Route,
  Link,
  Navigate
} from "react-router-dom";

import SelectedReci from './ReciSelected.js'
import CookbookSelected from './CookbookSelected.js'
import MenuSelected from './MenuSelected.js'
import LiveStream from './LiveStream.js'
import CalendarSelected from './CalendarSelected.js'
import MyHealth from './MyHealth.js'


import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";

import { auth, db, logout } from "./firebase";
import { query, collection, getDocs, where } from "firebase/firestore";
import Category from "./Category.js";
import { Nav, Navbar, NavDropdown, Container } from 'react-bootstrap'
import Booking from "./Components/Booking.js";
import CalendarCallback from "./utils/CalendarCallback.js";
import Sidebar from "./Components/Sidebar.js";

const headersData = [

   {
    label: "Cookbooks",
     href: "/Cookbooks",
   },


];
const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: "red",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

const NAVBAR_HEIGHT = '64px';  // Standard Material-UI AppBar height

export default function Header1() {
  const { header, logo } = useStyles();

  const [expanded, setExpanded] = useState(false);

  const closeMenu = () => setExpanded(false);

  const DisplayDesktop = () => {
    const [user, loading, error] = useAuthState(auth);
    const [login, setLogin] = useState(false);

    const navigate = useNavigate();
    const fetchUserName = async () => {
      try {
        const q = query(collection(db, process.env.REACT_APP_COLLECTION_USERS), where("uid", "==", user?.uid));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          setLogin(true);
        } else {
          setLogin(false);

        }

      } catch (err) {
        console.error(err);
      }
    };
    useEffect(() => {
      if (loading) return;

      if (user) fetchUserName();

    }, [user, loading]);

    return (
      <>
        {
          (login === false) ? (
            <Navbar
              collapseOnSelect
              expand="lg"
              className="navbar-dark"
              style={{ 
                padding: '0px 20px',
                backgroundColor: '#ff0000',
                width: '100%',
                height: NAVBAR_HEIGHT,
                minHeight: NAVBAR_HEIGHT
              }}
              expanded={expanded}
            >
              <Navbar.Brand>
                <Link to="/home" className="nav-link" style={{ color: "#fff" }} onClick={closeMenu}>
                  Reci
                </Link>
              </Navbar.Brand>
              <Navbar.Toggle
                aria-controls="responsive-navbar-nav"
                onClick={() => setExpanded(!expanded)}
              />
              <Navbar.Collapse id="responsive-navbar-nav" style={{ backgroundColor: '#ff0000' }}>
                <Nav className="me-auto">
                 <Link to="/cookbooks" className="nav-link" onClick={closeMenu}>
                    Cookbooks
                  </Link>
                </Nav>
                <Nav>
                  <Link to="/login" className="nav-link" onClick={closeMenu}>
                    Login
                  </Link>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          ) : (login === true) ? (
            <Sidebar 
              setLogin={setLogin} 
              style={{
                height: NAVBAR_HEIGHT,
                minHeight: NAVBAR_HEIGHT
              }}
            />
          ) : ""
        }
      </>
    );
  };

  const femmecubatorLogo = (
    <Link to="/Home" style={{ textDecoration: 'none', color: 'white' }}><Typography variant="h6" component="h1" className={logo}>
      Reci
    </Typography></Link>
  );

  const getMenuButtons = () => {
    return headersData.map(({ label, href }) => {
      return (
        <Button
          {...{
            key: label,
            color: "inherit",
            to: href,
            component: RouterLink,
          }}
        >
          {label}
        </Button>
      );
    });
  };

  return (
    <header>
      <AppBar className={header}>{DisplayDesktop()}</AppBar>


      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Home" element={<Home />} />
        <Route path="/Cookbooks" element={<Cookbooks />} />
        <Route path="/Menus" element={<Menus />} />
        <Route path="/Profiles" element={<Profiles />} />
        <Route path="/ProfileSelected/:username" element={<ProfileSelected />} />
        <Route path="/booking" element={<Booking />} />
        <Route path="/calender_callback" element={<CalendarCallback />} />
        <Route path="/category/:category" element={<Category />} />
        <Route path="about" element={<About />} />
        <Route path="/SelectedReci/:id" element={<SelectedReci />} />
        <Route path="/CookbookSelected/:id" element={<CookbookSelected />} />
        <Route path="/MenuSelected" element={<MenuSelected />} />
        <Route path="/LiveStream" element={<LiveStream />} />
        <Route path="/CalendarSelected" element={<CalendarSelected />} />
        <Route path="/MyHealth" element={<MyHealth />} />

      </Routes>

    </header>


  );
}


function About() {
  return (
    <>
      <main>
      <h2>About Us</h2>
      <p>
        Reci that is a social media site that allows users to post recipes and re-post recipes. Users can post recis by posting a video and having the recipes auto-generate using Reci AI or manually post videos and recipes.
      </p>
      </main>
      <nav>
        <Link to="/Home">Home</Link>
      </nav>
    </>
  );
}
