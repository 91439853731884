import React, { useState, useEffect, Component, useRef } from 'react';
import logo from './ReciLogo.png';
import YouTube from 'react-youtube';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Header1 from './header1.js'
import SelectedReci from './ReciSelected.js'
import { ImgOverlay } from 'image-overlay-react'
import { Router, Routes, Route, Link, useParams } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  makeStyles,
  Button,
} from "@material-ui/core";
import Serving_Size_Icon from './images/serving_size.png'
import Min_Cook_Icon from './images/min_cook.png'
import ReciLogo from './images/ReciLogo.png'
import Gem_Badge from './images/gem_badge.png'
import Shelf_Icon from './images/shelf_filled.png'
import User_Icon from './images/user.png'
import Website_Icon from './images/website.png'
import Cook_Icon from './images/cook.png'
import Calendar_Icon from './images/calendar.png'
import Share_Icon from './images/share.png'
import Quantity_Icon from './images/quantity.png'
import Units_Icon from './images/Units.png'
import Ingredient_Icon from './images/ingredient.png'
import Nutrition_Icon from './images/nutrition.png'
import Carbs_Icon from './images/carbs.png'
import Energy_Icon from './images/energy.png'
import Protein_Icon from './images/protein.png'
import Fats_Icon from './images/fats.png'
import Sugar_Icon from './images/sugar.png'
import Nutrition_Border from './images/Nutrient_border.png'
import Download_PDF from './images/PDF Download.png'
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  RedditShareButton,
  TumblrShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookMessengerIcon,
  EmailIcon,
  LinkedinIcon,
  PinterestIcon,
  RedditIcon,
  TumblrIcon,
  TwitterIcon,
  WeiboIcon,
  WhatsappIcon,
} from "react-share";
import ReactPlayer from 'react-player'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Overlay from 'react-image-overlay'
import { connect, useDispatch, useSelector } from 'react-redux';
import ProfileImage from './images/profile.png'
import { useAuthState } from "react-firebase-hooks/auth";
import { insertRecipe, auth, db, logout, storage } from "./firebase";
import store from './_store/configurestore.js';

import { changepost } from './_actions/postActions'
import { changeprofile } from './_actions/profileActions'
import { query, collection, onSnapshot, where } from "firebase/firestore";
import { Card } from 'react-bootstrap'
import QRCode from 'react-qr-code'
import generatePDF from 'react-to-pdf';

function ReciSelectedPrint({ data }) {
  if (!data) return null;

  return (
    <div className="print-container" style={{
      margin: '0 auto',
      maxWidth: '1000px',
      textAlign: 'center',
      wordBreak: 'break-word',
      wordWrap: 'break-word',
      fontSize: '14px',
      pageBreakAfter: 'avoid',
      pageBreakBefore: 'avoid'
    }}>
      <p style={{ margin: '3px 0' }}><b>{data.Cuisine}</b>: {data.Title}</p>
      <p style={{ margin: '3px 0' }}>
        <b>Author: </b>
        {data.author.map((name, index) => (
          <span key={name}>
            {data.author[index].Name}
            {index < data.author.length - 1 ? ', ' : ''}
          </span>
        ))}
      </p>

      <p style={{ margin: '3px 0' }}>
        <img src={Serving_Size_Icon} alt="Serving Size" width="12" height="12" /> 
        Serving Size: {data.serving_size} 
        <img src={Min_Cook_Icon} alt="Min Cook" width="12" height="12" style={{ marginLeft: '8px' }} /> 
        Min Cook: {data.min_cook} min
      </p>

      <div style={{
        margin: '8px auto',
        maxWidth: '150px',
        width: '100%',
      }}>
        <QRCode
          size={256}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          value={data.YouTube_URL}
          viewBox={'0 0 256 256'}
        />
      </div>

      <h4 style={{ 
        fontSize: '14px', 
        margin: '8px 0 3px 0'
      }}>Method</h4>
      
      <div style={{ 
        margin: '3px auto',
        width: '95%',
        textAlign: 'center'
      }}>
        {data.Reci.Steps.map((item, index) =>
          <div key={item} style={{ 
            marginBottom: '5px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
            <p style={{
              margin: '0 auto',
              fontSize: '14px',
              lineHeight: '1.1'
            }}>
              <b>Step {index + 1}</b>: {item}
            </p>
          </div>
        )}
      </div>

      <h4 style={{ 
        fontSize: '14px',
        margin: '8px 0 3px 0'
      }}>{data.Reci.Ingredients.length} Ingredients</h4>
      
      <div style={{ 
        width: '95%',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '3px'
      }}>
        {data.Reci.Ingredients.map((name, index) =>
          <p key={name} style={{
            fontSize: '14px',
            margin: '0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '5px'
          }}>
            <img src={Ingredient_Icon} alt="Ingredient" width="12" height="12" />
            <b>Ingredient:</b> {data.Reci.Ingredients[index].Ingredient}
            <img src={Units_Icon} alt="Amount" width="12" height="12" />
            <b>Amount:</b> {data.Reci.Ingredients[index].Amount} {data.Reci.Ingredients[index].Units}
          </p>
        )}
      </div>
    </div>
  );
}

export default ReciSelectedPrint;
