import React, { useState, useEffect, Component, } from 'react';
import logo from './ReciLogo.png';
import {db} from './firebase';
import YouTube from 'react-youtube';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import American from './images/american.png'
import {
  AppBar,
  Toolbar,
  Typography,
  makeStyles,
  Button,
} from "@material-ui/core";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Serving_Size_Icon from './images/serving_size.png'
import Min_Cook_Icon from './images/min_cook.png'
import ReciLogo from './images/ReciLogo.png'
import Gem_Badge from './images/gem_badge.png'
import Shelf_Icon from './images/shelf_filled.png'
import User_Icon from './images/user.png'
import Website_Icon from './images/website.png'
import Cook_Icon from './images/cook.png'
import Calendar_Icon from './images/calendar.png'
import Share_Icon from './images/share.png'
import { Routes, Route, Link, useSearchParams, useParams } from "react-router-dom";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  RedditShareButton,
  TumblrShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookMessengerIcon,
  EmailIcon,
  LinkedinIcon,
  PinterestIcon,
  RedditIcon,
  TumblrIcon,
  TwitterIcon,
  WeiboIcon,
  WhatsappIcon,
} from "react-share";
import ReactTooltip from 'react-tooltip';
import { connect, useDispatch, useSelector } from 'react-redux';
import Nutrition_Icon from './images/nutrition.png'
import {changeprofile} from './_actions/profileActions'
import {changepost} from './_actions/postActions'
import ReactPlayer from 'react-player'
import { query, collection, where,onSnapshot, getDocs } from "firebase/firestore";
import { reciCategories } from './utils/dataUtils';
import ProfileImage from './images/profile.png'
import Container from 'react-bootstrap/Container'
import AddToCookbookPopup from './Components/AddToCookbookPopup';
import cookbook from './images/Catalog.png'
import { useNavigate } from "react-router-dom"; // Add this import

function Category(props) {
    const { category } = useParams();
    console.log('category', category);

    const [catInfo, setCatInfo] = useState([]);
    const [Recis, setRecis] = useState([]);
    const [isAddToCookbookOpen, setIsAddToCookbookOpen] = useState(false);
    const [selectedReciId, setSelectedReciId] = useState(null);
    const [loggedUserId, setLoggedUserId] = useState(null);
    const [info, setInfo] = useState(null);

    const dispatch = useDispatch();
    const user = useSelector(state => state.user);

    const opts = {
        height: '400',
        width: '100%',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
        },
    };
    useEffect(() => {
        fetchUserName();
      }, []);
      useEffect(() => {
        const q = query(collection(db, 'posts'))
        onSnapshot(q, (querySnapshot) => {
          setRecis(querySnapshot.docs.map(doc => ({
            id: doc.id,
            data: doc.data(),
          })))
        })
      }, []);
    const fetchUserName = async () => {
        try {
          const q = query(collection(db, process.env.REACT_APP_COLLECTION_USERS), where("uid", "==", user?.uid));
          const doc = await getDocs(q);
          const data = doc.docs[0].data();
          setInfo(doc.docs[0]?.id)
          console.log('loggedinuser', data);
          setLoggedUserId(data);
        } catch (err) {
          console.error(err);
        }
      };

    useEffect(() => {
        const q = query(collection(db, process.env.REACT_APP_COLLECTION_POSTS), where("Cuisine", "==", category))
        onSnapshot(q, (querySnapshot) => {
            setRecis(querySnapshot.docs.map(doc => ({
                id: doc.id,
                data: doc.data(),
            })))
        })
        let categoryData = reciCategories.find(cat => cat.catlink === category);
        console.log(categoryData, "categoryData")
        setCatInfo(categoryData);
    }, [category]);

  
    // Modify the click handler to include console.logs for debugging
    const navigate = useNavigate(); // Initialize navigate
    const handleCookbookClick = (id) => {
      console.log('Cookbook button clicked', id); // Debug log
      if (!loggedUserId) {
        navigate('/login'); // Now navigate is defined
        return;
      }
      setSelectedReciId(id);
      setIsAddToCookbookOpen(true);
      console.log('Popup should be open:', isAddToCookbookOpen); // Debug log
    };

    return (
        <div className="App">
            <header className="App-header">

            </header>

            <div style={{padding:'0px 20px'}}>
            <Container className='text-center'>
                <img src={catInfo?.catimg} alt={catInfo?.catlabel} width="100" height="100" />

                <h2><b>{catInfo?.catlabel} Recis</b></h2>
            </Container>
                <Grid container spacing={2}>
                    {Recis?.map(({ id, data }) => (
                        <Grid item xs={12} sm={12} md={4} key={id} className="recis_video_box">
                            <Paper>
                                <div className='recis_video_box_detail'>
                                    <p><b>{data.Cuisine} :</b> {data.Title}</p>
                                    <p><b>Author: </b>
                                  {/*  <Link onClick={() => dispatch(changeprofile(data.user_id))} to={`/ProfileSelected/${data.userdoc_id}`}>{data.author ? data.author[0]?.Name: ""} </Link>*/}

                                         {data.author?.map((name, index) => <a key={name} href={data.author[index].profile_URL}>{data.author[index].Name}</a>)}
                                    </p>
                                    <Row className='reci-details mb-4'>
                                        <Col xl={6}>
                                            <img src={Serving_Size_Icon} alt="Serving Size" width="20" height="20" /> <span>Serving Size: {data.serving_size}</span>
                                        </Col>
                                        <Col xl={6}>
                                        <img src={Min_Cook_Icon} style={{marginRight:'5px'}} alt="Min Cook" width="20" height="20" /><span>Min Cook: {data.min_cook}min</span>
                                        </Col>
                                    </Row>
                                    <ReactPlayer url={data.YouTube_URL} style={{ backgroundColor: '#000000' }} width="100%" controls="true" />
                                    <div className="mx-0 profile_list reci-tools">
                                      {/*<Link onClick={() => dispatch(changeprofile(data.user_id))} to={`/ProfileSelected/${data.userdoc_id}`}><Button as={Col} variant="primary">
                                            <img src={data.profile_pic ? data.profile_pic : ProfileImage} alt="User" width="30" height="30" />

                                        </Button></Link> */}
                                        <Link to={`/SelectedReci/${id}`}><Button onClick={() => dispatch(changepost(data.post_id))} as={Col} variant="secondary" className="mx-2">
                                            <img src={Cook_Icon} alt="Cook this Reci" width="35" height="35" />
                                        </Button></Link>
                                        {/* <Button 
                                         onClick={(e) => {
                                             e.preventDefault();
                                             handleCookbookClick(id);
                                             }} 
                                         as={Col} 
                                        variant="primary"
                                        >
                                        <img src={cookbook} alt="Add to Cookbook" width="30" height="30" />
                                        </Button> */}
                                        {/* <Button as={Col} data-tip="Carbs: ${data.Nutrition.Carbs.Amount} ${data.Nutrition.Carbs.Units}" variant="primary"><img src={Nutrition_Icon} alt="See Reci Nutrition Label (Sugars, Carbs, Energy, Fats, and Proteins)" width="35" height="35" /></Button> */}
                                        {/* <ReactTooltip /> */}
                                        {
                                            data.Website_URL && <a href={data.Website_URL} target="_blank"><Button as={Col} variant="primary"><img src={Website_Icon} alt="Website" width="30" height="30" /></Button></a>
                                        }
                                    </div>
                                    <div className="mx-0 text-center">
                                        <EmailShareButton as={Col} url={data.Website_URL? data.Website_URL : data.YouTube_URL} ><EmailIcon size={32} round={true} /></EmailShareButton>
                                        <FacebookShareButton as={Col} url={data.Website_URL? data.Website_URL : data.YouTube_URL} ><FacebookMessengerIcon size={32} round={true} /></FacebookShareButton>
                                        <LinkedinShareButton as={Col} url={data.Website_URL? data.Website_URL : data.YouTube_URL} ><LinkedinIcon size={32} round={true} /></LinkedinShareButton>
                                        <PinterestShareButton as={Col} url={data.Website_URL? data.Website_URL : data.YouTube_URL}  media={data.Website_URL? data.Website_URL : data.YouTube_URL}><PinterestIcon size={32} round={true} /></PinterestShareButton>
                                        <RedditShareButton as={Col} url={data.Website_URL? data.Website_URL : data.YouTube_URL} ><RedditIcon size={32} round={true} /></RedditShareButton>
                                        <TumblrShareButton as={Col} url={data.Website_URL? data.Website_URL : data.YouTube_URL} ><TumblrIcon size={32} round={true} /></TumblrShareButton>
                                        <TwitterShareButton as={Col} url={data.Website_URL? data.Website_URL : data.YouTube_URL} ><TwitterIcon size={32} round={true} /></TwitterShareButton>
                                        <WhatsappShareButton as={Col} url={data.Website_URL? data.Website_URL : data.YouTube_URL} ><WhatsappIcon size={32} round={true} /></WhatsappShareButton>
                                    </div>
                                </div>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </div>

            <AddToCookbookPopup
                open={isAddToCookbookOpen}
                handleClose={() => setIsAddToCookbookOpen(false)}
                reciId={selectedReciId}
                userId={loggedUserId?.user_id}
            />
        </div>
    );
}

export default Category;
