import React, { useState, useEffect, Component } from 'react';
import logo from '../ReciLogo.png';
import { reinsertRecipe, auth, db } from '../firebase';
import YouTube from 'react-youtube';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Header1 from '../header1.js'
import {
  AppBar,
  Toolbar,
  Typography,
  makeStyles,
  Button,
} from "@material-ui/core";
import Serving_Size_Icon from '../images/serving_size.png'
import { useAuthState } from "react-firebase-hooks/auth";

import orignal_recipie from '../images/original.png'
import copy_recipie from '../images/repost.png'
import retweetImg from '../images/retweet.png'
import ProfileImage from '../images/profile.png'
import ReciAIIcon from '../images/reci_ai_logo.png'
import Min_Cook_Icon from '../images/min_cook.png'
import ReciLogo from '../images/ReciLogo.png'
import Gem_Badge from '../images/gem_badge.png'
import Shelf_Icon from '../images/shelf_filled.png'
import User_Icon from '../images/user.png'
import Website_Icon from '../images/website.png'
import Cook_Icon from '../images/cook.png'
import Calendar_Icon from '../images/calendar.png'
import Share_Icon from '../images/share.png'
import Health_Icon from '../images/Health.png'
import LiveStream_Icon from '../images/LiveStream.png'
import { Routes, Route, Link, useParams, useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import store from '../_store/configurestore.js';
import { connect, useDispatch, useSelector } from 'react-redux';
import { changepost } from '../_actions/postActions'
import Nutrition_Icon from '../images/nutrition.png'
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  RedditShareButton,
  TumblrShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookMessengerIcon,
  EmailIcon,
  LinkedinIcon,
  PinterestIcon,
  RedditIcon,
  TumblrIcon,
  TwitterIcon,
  WeiboIcon,
  WhatsappIcon,
} from "react-share";
import ReactPlayer from 'react-player'
import { query, collection, getDocs, onSnapshot, where } from "firebase/firestore";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { changeprofile } from '../_actions/profileActions'


function MyRecipes(props) {
  const [user, loading, error] = useAuthState(auth);

  const [logged_user_id, setLoggedUserId] = useState("");
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);


  const [info, setInfo] = useState([]);
  const [Recis, setRecis] = useState([]);
  const [Users, setUsers] = useState([]);
  const [RepostId, SetRespotId] = useState([]);
  const [getRepost, setRepostRecis] = useState([]);

  const state = useSelector((state) => state);
  const params = useParams();
  const opts = {
    height: '600',
    width: '90%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
    },
  };

  const dispatch = useDispatch();
  const [inputField, setInputField] = useState([])

  const modalstyle = {
    display: 'none'
  };



  const ColoredLine = ({ color }) => (
    <hr
      style={{
        color,
        backgroundColor: color,
        height: .5
      }}
    />
  );



  useEffect(() => {
    console.log(user, "user")

    fetchUserName();
  }, [user, loading]);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const fetchUserName = async () => {
    try {
      const q = query(collection(db, process.env.REACT_APP_COLLECTION_USERS), where("uid", "==", user?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      console.log('loggedinuser', data);
      setLoggedUserId(data);

      const q2 = query(collection(db, process.env.REACT_APP_COLLECTION_POSTS), where("userdoc_id", "==", doc.docs[0]?.id))
      onSnapshot(q2, (querySnapshot) => {
        setRecis(querySnapshot.docs.map(doc => ({
          id: doc.id,
          data: doc.data(),
        })))
      })

    } catch (err) {
      console.error(err);
    }
  };

  const popuphandler = (postid) => {
    console.log(postid);
    SetRespotId(postid)
    document.getElementById('myModal').style = 'display:block';
  };

  const popupClosehandler = () => {
    document.getElementById('myModal').style = 'display:none';
  };

  const reposthandler = async () => {
    console.log('RepostId', RepostId)
    document.getElementById('myModal').style = 'display:none';

    const q = query(collection(db, process.env.REACT_APP_COLLECTION_POSTS), where("myID", "==", RepostId));
    const doc = await getDocs(q);
    const data = doc.docs[0].data();
    setTimeout(function () {
      data.userdoc_id = doc.docs[0].id;
      data.user_id = doc.docs[0].id;
      data.profile_pic = logged_user_id.profile_pic;
      reinsertRecipe(data)
    }, 1500);


  };

  useEffect(() => {
    const script = document.createElement('script');

    script.src = "https://connect.bingewave.com/connect.js";
    script.async = true;

    document.body.appendChild(script);

    setTimeout(() => { window.BingewaveConnector?.init(); }, 1000);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  console.log(Recis, "Recis")

  return (
    <div className={`${user ? 'page-container' : 'web-container'}`}>
      <div className='d-flex justify-content-between mb-2'>
        <h1 className='title-2'> Recis </h1>
        <button className='theme-button' onClick={handleClick}>Add Reci <i class="fa fa-plus" ></i></button>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem onClick={() => navigate("/addrecipe")}>
            Manual Reci Upload
          </MenuItem>
          <MenuItem onClick={() => navigate("/airecipe")}>
            Reci AI Upload <img style={{height:30, width:25}} src={ReciAIIcon} />
          </MenuItem>
        </Menu>
      </div>

      {
        Recis.length ?
          <Grid container spacing={2}>
            {Recis?.reverse()?.map(({ id, data }) => (
              <Grid item sm={12} md={6} lg={4} key={id} className="recis_video_box">
                <Paper>
                <Link style={{ color: 'black', textDecoration: 'none'}} to={`/SelectedReci/${data.myID}`}>
                  <div className='recis_video_box_detail'>

                    <p className='relative'><b>{data.Cuisine}</b>: {data.Title}
                      <span className='editReci'>
                        {data.own_recipe == 1 && (
                          <Link to={`/addrecipe/${id}`}>
                            <i class="fa fa-edit" ></i>

                          </Link>
                        )}
                      </span> </p>
                      <p><b>Author:</b>
                      {/*   <Link onClick={() => dispatch(changeprofile(data.user_id))} to={`/ProfileSelected/${data?.user_id ? data?.user_id : data.userdoc_id}`}>{data.author ? data.author[0]?.Name : ""} </Link>*/}
                        {data.author?.map((name, index) => <span key={name} >{data.author[index].Name}</span>)}
                      </p>

                    <div className='reci_headerss'>
                      {data.own_recipe == 1 ? (<p><img src={orignal_recipie} alt="orignal image" width="20" height="20" /> <span>Orignal</span></p>) : (<p><img src={copy_recipie} alt="orignal image" width="20" height="20" /> <span>Repost</span></p>)}
                    </div>

                    <ReactPlayer url={data.YouTube_URL} style={{ backgroundColor: '#000000' }} width="100%" controls="true" />

                  </div>
                  </Link>
                </Paper>
              </Grid>
            ))}
          </Grid>
          : <div className="text-center" >No records found</div>}




      <div id="myModal" className="modal" style={modalstyle}>

        <div className="modal-content">
          <span className="close">&times;</span>
          <div className="modal-body">
            <p>Do you really want to repost in your profile ?</p>
          </div>
          <div className="modal-footer">
            <Button onClick={() => popupClosehandler()} as={Col} variant="primary" className='btn-no'>
              No </Button>
            <Button onClick={() => reposthandler()} as={Col} variant="primary" className='btn-yes' >
              Yes </Button>
          </div>
        </div>

      </div>

    </div>
  );
}

export default MyRecipes;
