import React, { useState, useEffect, Component } from 'react';
import { db } from './firebase';
import Search_Icon from './images/search_icon.png'
import { Routes, Route, Link, useParams } from "react-router-dom";

import { connect, useDispatch, useSelector } from 'react-redux';
import { query, collection, getDocs, where, onSnapshot } from "firebase/firestore";
import { Row, Col, InputGroup, Form, Button, ProgressBar, FormControl } from 'react-bootstrap';
import logo from './ReciLogo.png';
import original_recipe_icon from './images/original.png';
import repost_icon from './images/repost.png';
import ReciLogo from './ReciLogo.png';

function SearchData() {

  const [Info, setInfo] = useState([]);
  const [Result, setResult] = useState([]);
  const [search, setSearch] = useState('');
  const { id } = useParams();

  const dispatch = useDispatch();

  const state = useSelector((state) => state);

  const divStyle = {
    paddingTop: '20px'
  };
  const opts = {
    height: '400',
    width: '100%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
    },
  };

  const searchResultsStyle = {
    maxHeight: '300px',  // Adjust height as needed
    overflowY: 'auto',
    backgroundColor: 'white',
    borderRadius: '0 0 4px 4px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    position: 'absolute',
    width: '100%',
    zIndex: 1000
  };

  useEffect(() => {
    const q = query(collection(db, process.env.REACT_APP_COLLECTION_POSTS))
    onSnapshot(q, (querySnapshot) => {
      setInfo(querySnapshot.docs.map(doc => ({
        id: doc.id,
        data: doc.data(),
      })))
    })
  }, []);


  const handleChange = event => {
    setSearch(event.target.value);

    var results = [];
    for (var i = 0; i < Info.length; i++) {
      if (!event.target.value.toLowerCase()) {
        results = [];
      }
      else if (Info[i].data.Title.toLowerCase().includes(event.target.value.toLowerCase()) || Info[i].data.Cuisine.toLowerCase().includes(event.target.value.toLowerCase())) {
        results.push(Info[i]);
      }

      setResult(results)


    };
  }

  return (
    <div style={divStyle}>

      <InputGroup className="mb-5 searchbar">
        <InputGroup.Text id="basic-addon1"><img src={Search_Icon} alt="Search Icon" width="24" height="24" /></InputGroup.Text>
        <input
          type="text"
          id="search"
          name="search"
          className='seaech-input'
          placeholder='Search for a Reci'
          onChange={handleChange}
          value={search}
        />
        <div className='rc_seachresults' style={searchResultsStyle}>
          {
            Result.map(({ id, data }) => (
              <div key={id} hidden={!search} className="box-search">
                <Link to={`/SelectedReci/${id}`} style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '8px 16px',
                  textDecoration: 'none',
                  color: 'inherit'
                }}>
                {/*  <img 
                    src={data.profile_pic || ReciLogo}
                    alt="User" 
                    style={{
                      width: '30px',
                      height: '30px',
                      borderRadius: '50%',
                      marginRight: '12px'
                    }}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = ReciLogo;
                    }}
                  /> */}
                  <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                      <div>{data.Title}</div>
                      <small style={{ color: '#666' }}>{data.UserName}</small>
                    </div>
                    <img 
                      src={data.own_recipe === "1" ? original_recipe_icon : repost_icon}
                      alt={data.own_recipe === "1" ? "Original Recipe" : "Repost"}
                      style={{
                        width: '20px',
                        height: '20px',
                        marginLeft: '12px'
                      }}
                    />
                  </div>
                </Link>
              </div>
            ))
          }
        </div>
      </InputGroup>





      {/* <div className='rc_searchbox_wrap'>
        <div className='rc_searchbox'>
          <img src={Search_Icon} alt="Search Icon" width="24" height="24" />
          <input
            type="text"
            id="search"
            name="search"
            className='searchField'
            placeholder='Search for a Reci'
            onChange={handleChange}
            value={search}
          />
        </div>
        <div className='rc_seachresults'>
          {
            Result.map(({ id, data }) => (
              <div key={id} hidden={!search} className="box-search">
                <Link to={`/SelectedReci/${id}`}>{data.Title}</Link>
              </div>
            ))
          }
        </div>
      </div> */}



    </div>
  );
}



export default SearchData;
