import React, { useState, useEffect } from 'react';
import { auth, db } from '../firebase';
import { useAuthState } from "react-firebase-hooks/auth";
import { query, collection, onSnapshot, where } from "firebase/firestore";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Link } from "react-router-dom";
import ReciLogo from '../images/ReciLogo.png';
import Button from '@material-ui/core/Button';
import { useNavigate } from 'react-router-dom';
import { changepost } from '../_actions/postActions'
import { selectedcookbook } from '../_actions/cookbookActions'


function MyCookbooks() {
  const [user] = useAuthState(auth);
  const [myCookbooks, setMyCookbooks] = useState([]);
  const navigate = useNavigate();
  const [Recis, setRecis] = useState([]);



  useEffect(() => {
    if (user) {
      const cookbooksQuery = query(
        collection(db, "cookbooks"),
        where("user_id", "==", user.uid)
      );

      const unsubscribe = onSnapshot(cookbooksQuery, (querySnapshot) => {
        setMyCookbooks(querySnapshot.docs.map(doc => ({
          id: doc.id,
          data: doc.data(),
        })));
      });

      return () => unsubscribe();
    }
  }, [user]);

  return (
    <div style={{ padding: '20px', width: '100%', boxSizing: 'border-box', marginTop: '80px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h1 className='title-2'>My Cookbooks</h1>

          <button 
            variant="contained" 
            style={{ 
              backgroundColor: 'red', 
              color: 'white', 
              padding: '10px 20px',
              border: 'none',
              borderRadius: '10px'
            }} 
            onClick={() => navigate('/addcookbook')}
          >
            Add Cookbook <i class="fa fa-plus" ></i>
          </button>
      </div>

      {myCookbooks.length > 0 ? (
        <Grid container spacing={2}>
          {myCookbooks.map(({ id, data }) => (
            <Grid item xs={12} sm={6} md={4} lg={4} key={id}>
              <Paper className="cookbook-card" style={{ padding: '15px', textAlign: 'center' }}>
                <Link to={`/CookbookSelected/${id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                  <img
                    src={data.icon || ReciLogo}
                    alt="Cookbook cover"
                    style={{
                      width: '100%',
                      height: '200px',
                      objectFit: 'cover',
                      borderRadius: '8px',
                      marginBottom: '10px'
                    }}
                  />
                  <p style={{ 
                      margin: 0,
                      fontSize: '1.1em',
                      fontWeight: 'bold',
                      color: '#333'
                    }}>{data.book_title}</p>
                  <p><b><span style={{color:'red'}}>Recis:</span></b> {data.posts.length}</p>
                </Link>
              </Paper>
            </Grid>
          ))}
        </Grid>
      ) : (
        <div className="text-center mt-4">
          <p>No cookbooks found. Create your first cookbook!</p>
        </div>
      )}
    </div>
  );
}



export default MyCookbooks;
