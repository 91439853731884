import React, { useState, useEffect } from 'react';
import { Modal, Button, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import { collection, query, where, getDocs, doc, updateDoc, arrayUnion } from "firebase/firestore";
import { db } from '../firebase';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)',
    padding: theme.spacing(4),
    borderRadius: '8px',
    minWidth: '300px',
    maxWidth: '90%',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 200,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(3),
    gap: theme.spacing(2),
  },
  button: {
    borderRadius: '20px',
    padding: '8px 24px',
    '&.confirm': {
      backgroundColor: 'red',
      color: 'white',
      '&:hover': {
        backgroundColor: '#d32f2f',
      },
    },
  },
}));

function AddToCookbookPopup({ open, handleClose, reciId, userId }) {
  const classes = useStyles();
  const [cookbooks, setCookbooks] = useState([]);
  const [selectedCookbook, setSelectedCookbook] = useState('');
  const [loading, setLoading] = useState(true);

  console.log("AddToCookbookPopup rendered with userId:", userId); // Debug log

  useEffect(() => {
    if (open && userId) {
      if (!userId) {
        console.warn("No userId provided to AddToCookbookPopup");
        setLoading(false);
        return;
      }
      fetchCookbooks();
    }
  }, [open, userId]);

  const fetchCookbooks = async () => {
    try {
      console.log("Fetching cookbooks for userId:", userId);
      const q = query(
        collection(db, "cookbooks"), 
        where("user_id", "==", userId)
      );
      const querySnapshot = await getDocs(q);
      const cookbookList = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setCookbooks(cookbookList);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching cookbooks:", error);
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedCookbook) return;

    try {
      const cookbookRef = doc(db, "cookbooks", selectedCookbook);
      await updateDoc(cookbookRef, {
        posts: arrayUnion(reciId)
      });
      handleClose();
      setSelectedCookbook('');
    } catch (error) {
      console.error("Error adding Reci to cookbook:", error);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={classes.modal}
      aria-labelledby="add-to-cookbook-modal"
    >
      <div className={classes.paper}>
        <h2 style={{ marginBottom: '20px', textAlign: 'center' }}>
          Add to Cookbook
        </h2>
        
        {loading ? (
          <p>Loading cookbooks...</p>
        ) : cookbooks.length === 0 ? (
          <p>No cookbooks found. Create a cookbook first!</p>
        ) : (
          <form onSubmit={handleSubmit} className={classes.form}>
            <FormControl className={classes.formControl}>
              <InputLabel>Select Cookbook</InputLabel>
              <Select
                value={selectedCookbook}
                onChange={(e) => setSelectedCookbook(e.target.value)}
                fullWidth
              >
                {cookbooks.map((cookbook) => (
                  <MenuItem key={cookbook.id} value={cookbook.id}>
                    {cookbook.book_title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <div className={classes.buttonContainer}>
              <Button
                onClick={handleClose}
                variant="outlined"
                className={classes.button}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                disabled={!selectedCookbook}
                variant="contained"
                className={`${classes.button} confirm`}
              >
                Add to Cookbook
              </Button>
            </div>
          </form>
        )}
      </div>
    </Modal>
  );
}

export default AddToCookbookPopup; 