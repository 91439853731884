import React, { useState } from 'react';
import { db, storage } from '../firebase'; // Ensure you have Firebase initialized
import { collection, addDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from '../firebase'; // Import auth for user state
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import SearchAddToCookbook from '../SearchAddToCookbook'; // Import the Search component

function AddCookbookComponent() {
  const [user] = useAuthState(auth);
  const [bookTitle, setBookTitle] = useState('');
  const [icon, setIcon] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [selectedPosts, setSelectedPosts] = useState([]); // Array to hold selected posts
  const navigate = useNavigate(); // Initialize useNavigate
  const [selectedPostIds, setSelectedPostIds] = useState([]); // State to hold selected post IDs

  const handleIconChange = (e) => {
    setIcon(e.target.files[0]);
  };

  const handleSelectPost = (id, postData) => {
    console.log(`Post added to cookbook: ${id}`); // Debug log to confirm selection
    if (!selectedPostIds.includes(id)) { // Prevent duplicates
      setSelectedPostIds(prevIds => [...prevIds, id]);
      setSelectedPosts(prevPosts => [...prevPosts, postData]); // Add post data to selectedPosts
    } else {
      console.log(`Post ${id} is already in the cookbook.`); // Log if already selected
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!bookTitle || !icon) {
      setError('Please add a book title and upload a cookbook icon.');
      return;
    }

    setLoading(true);
    setError('');

    try {
      // Upload the icon to Firebase Storage
      const iconRef = ref(storage, `cookbook-icons/${icon.name}`);
      await uploadBytes(iconRef, icon);
      const iconURL = await getDownloadURL(iconRef);

      // Add the cookbook to Firestore
      const cookbookData = {
        book_title: bookTitle,
        posts: selectedPosts.map(post => post.id), // Store post_id values
        icon: iconURL,
        user_id: user.uid,
        user_name: user.uid
        // book_id and myID will be set after the document is created
      };

      const docRef = await addDoc(collection(db, "cookbooks"), cookbookData);
      // Now we can update the book_id and myID with the docRef.id
      await updateDoc(docRef, {
        book_id: docRef.id,
        myID: docRef.id
      });

      console.log("Cookbook added with ID: ", docRef.id);

      // Reset form fields
      setBookTitle('');
      setIcon(null);
      setSelectedPosts([]); // Reset selected posts

      // Navigate back to MyCookbooks page
      navigate('/mycookbooks');
    } catch (err) {
      console.error("Error adding cookbook: ", err);
      setError('Failed to add cookbook. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{
      minWidth: '80%', // Set the max width to 80% of the page
      margin: '0 auto',
      padding: '20px',
      border: '1px solid #ccc',
      borderRadius: '8px',
      position: 'absolute',
      top: '40%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }}>
      <h4 style={{
        backgroundColor: 'red', // Red background for the title
        color: 'white', // White text for the title
        width: '100%', // Full width
        textAlign: 'center', // Center text
        padding: '10px', // Padding for the title
        borderRadius: '8px 8px 0 0' // Rounded corners for the top
      }}>
        Add a New Cookbook
      </h4>
      <form onSubmit={handleSubmit} style={{ width: '100%' }}>
        <div style={{ marginBottom: '15px' }}>
          <label htmlFor="bookTitle">Book Title:</label>
          <input
            type="text"
            id="bookTitle"
            style={{
              padding: '10px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              width: '100%'
            }}
            value={bookTitle}
            onChange={(e) => setBookTitle(e.target.value)}
            required
          />
        </div>
        <div style={{ marginBottom: '15px' }}>
          <label htmlFor="icon">Upload Icon:</label>
          <input
            type="file"
            id="icon"
            style={{
              padding: '10px',
              border: '1px solid #ccc',
              borderRadius: '4px',
              width: '100%'
            }}
            accept="image/*"
            onChange={handleIconChange}
            required
          />
        </div>
        {/*<div style={{ marginBottom: '15px' }}>
          <label>Search and Add First:</label>
          <SearchAddToCookbook onSelect={handleSelectPost} /> 
        </div> */}
        {error && <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>}
        <button type="submit" style={{
          padding: '10px',
          color: 'white',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer',
          backgroundColor: 'red', // Red background for the button
          width: '100%'
        }} disabled={loading}>
          {loading ? 'Adding...' : 'Add Cookbook'}
        </button>
      </form>
    </div>
  );
}

export default AddCookbookComponent; 